import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TrackingConsentContextValueType,
  TrackingConsentState,
  TrackingConsents,
  TrackingConsentSet,
  ConsentConfigurationMap,
  CookieDefinition,
} from "./TrackingConsentManagerInterface";
import {
  loadConsentFromCookies,
  storeConsentInCookie,
} from "./consentPersistence";

export const TrackingConsentContext =
  createContext<TrackingConsentContextValueType>({
    functionalCookies: [],
    isInitialized: false,
    trackingConsent: { state: TrackingConsentState.consentUnknown },
    onUserUpdatedConsent: (newValue) => {
      console.error(
        `default 'onUserUpdatedConsent' was called with ${newValue}`
      );
    },
    consentConfigurations: {},
  });

interface TrackingConsentManagerProps {
  functionalCookies: CookieDefinition[];
  consentConfigurations: ConsentConfigurationMap;
}

export const TrackingConsentManager: FC<
  PropsWithChildren<TrackingConsentManagerProps>
> = ({ children, functionalCookies, consentConfigurations }) => {
  const [isInitialized, setIsInitialized] = useState<Boolean>(false);
  const [trackingConsent, setTrackingConsent] = useState<TrackingConsents>({
    state: TrackingConsentState.consentUnknown,
  });

  useEffect(() => {
    const initialisedState = loadConsentFromCookies(consentConfigurations);
    setTrackingConsent(initialisedState);
    setIsInitialized(true);
  }, [consentConfigurations]);

  useEffect(() => {
    if (isInitialized) {
      storeConsentInCookie(trackingConsent);
      Object.values(consentConfigurations).forEach((config) => {
        config.onConsentUpdated(trackingConsent);
      });
    }
  }, [consentConfigurations, isInitialized, trackingConsent]);

  // useMemo is used here so that the provided value doesn't change on
  // each render but only when the dependencies change.
  const providerValue = useMemo(
    () => ({
      functionalCookies,
      isInitialized,
      trackingConsent,
      onUserUpdatedConsent: (newConsents: TrackingConsentSet) => {
        setTrackingConsent({
          state: TrackingConsentState.consentKnown,
          consents: newConsents,
        });
      },
      consentConfigurations,
    }),
    [
      isInitialized,
      setTrackingConsent,
      trackingConsent,
      consentConfigurations,
      functionalCookies,
    ]
  );

  return (
    <TrackingConsentContext.Provider value={providerValue}>
      {children}
    </TrackingConsentContext.Provider>
  );
};
