// Generates a function that matches against the provided string
export const generateMatchesName = (name: string) => {
  return (cookies: Set<string>) => {
    return cookies.has(name) ? [name] : [];
  };
};

// Generates a function that finds strings with the provided prefix
export const generateStartsWith = (prefix: string) => {
  return (cookies: Set<string>) => {
    return Array.from(cookies).filter((name) => name.startsWith(prefix));
  };
};
