import { NextPageContext } from "next";
import {
  werePreferencesAlreadySetWithBorlabs,
  wereAnalyticsAlreadyAcceptedWithBorlabs,
} from "./borlabsCookies";
import {
  TrackingConsents,
  TrackingConsentState,
  ConsentConfigurationMap,
} from "./TrackingConsentManagerInterface";
import nookies from "nookies";

const consentCookieDefinition = {
  name: "od_cookies_consent",
  path: "/",
  maxAge: 7 * 24 * 60 * 60,
  sameSite: "Strict",
};

export const storeConsentInCookie = (consent: TrackingConsents) => {
  if (consent.state == TrackingConsentState.consentUnknown) {
    return;
  } else {
    const cookieValue = getCookieValueFromConsentScopes(consent.consents);
    nookies.set(undefined, consentCookieDefinition.name, cookieValue, {
      domain: getConsentCookieDomain(window.location.hostname),
      path: consentCookieDefinition.path,
      maxAge: consentCookieDefinition.maxAge,
      sameSite: consentCookieDefinition.sameSite,
    });
  }
};

export const loadConsentFromCookies = (
  consentConfigurations: ConsentConfigurationMap
): TrackingConsents => {
  return serverSideLoadConsentFromCookies(consentConfigurations, undefined);
};

export const serverSideLoadConsentFromCookies = (
  consentConfigurations: ConsentConfigurationMap,
  ctx?: NextPageContext
) => {
  const loadedODState = loadConsentFromODCookie(ctx);
  if (loadedODState.state == TrackingConsentState.consentKnown) {
    return loadedODState;
  } else {
    return loadConsentFromBorlabsCookie(consentConfigurations, ctx);
  }
};

const getConsentCookieDomain = (domain: string) => {
  const segments = domain.split(".");
  return segments.length === 1 ? segments[0] : segments.slice(-2).join(".");
};

const loadConsentFromODCookie = (ctx?: NextPageContext): TrackingConsents => {
  const consentCookie = nookies.get(ctx)[consentCookieDefinition.name];
  const consentCookieExists = consentCookie !== undefined;

  if (consentCookieExists) {
    return {
      state: TrackingConsentState.consentKnown,
      consents: extractConsentScopesFromCookieValue(consentCookie),
    };
  } else {
    return {
      state: TrackingConsentState.consentUnknown,
    };
  }
};

const extractConsentScopesFromCookieValue = (value?: string): Set<string> => {
  if (!value) return new Set();
  return new Set(value.split("|") as string[]);
};

const getCookieValueFromConsentScopes = (scopes: Set<string>): string => {
  return Array.from(scopes).join("|");
};

const loadConsentFromBorlabsCookie = (
  consentConfigurations: ConsentConfigurationMap,
  ctx?: NextPageContext
): TrackingConsents => {
  if (werePreferencesAlreadySetWithBorlabs(ctx)) {
    const analyticsAccepted = wereAnalyticsAlreadyAcceptedWithBorlabs();
    const consents = analyticsAccepted
      ? new Set(Object.keys(consentConfigurations))
      : new Set<string>();
    return {
      state: TrackingConsentState.consentKnown,
      consents,
    };
  } else {
    return { state: TrackingConsentState.consentUnknown };
  }
};

/**
 * This function detects cookies that are sent to the server which don't
 * match with the currently set consents. The server responds with 'Set-Cookie'
 * headers that reset those cookies in the browser. It deletes the conflicting
 * cookies for all subdomains of the current domain.
 *
 * @param ctx NextJS Page Context
 */
export const serverSideDeleteCookiesWithoutConsent = (
  ctx: NextPageContext,
  consentConfigurations: ConsentConfigurationMap
) => {
  const consentState = serverSideLoadConsentFromCookies(
    consentConfigurations,
    ctx
  );
  if (consentState.state == TrackingConsentState.consentUnknown) {
    return;
  }

  const cookies = nookies.get(ctx);
  const domains = getSubdomains(
    (ctx.req?.headers.host || "").split(":")[0].split(".")
  );

  const cookieNames = new Set(Object.keys(cookies));
  const cookiesToDelete = getCookiesToDelete(
    cookieNames,
    consentState.consents,
    consentConfigurations
  );

  for (const domain of domains) {
    for (const { name, path } of cookiesToDelete) {
      nookies.destroy(ctx, name, {
        path: path,
        domain: domain,
      });
    }
  }
};

const getSubdomains = (domains: string[]): string[] => {
  if (domains.length < 2) {
    return [domains.join(".")];
  }
  const results: string[] = [];
  for (let i = 0; i < domains.length - 1; i++) {
    results.push(domains.slice(i).join("."));
  }
  return results;
};

const getCookiesToDelete = (
  cookieNames: Set<string>,
  consentScopes: Set<string>,
  consentConfigurations: ConsentConfigurationMap
): { name: string; path: string }[] => {
  const cookiesToDelete: { name: string; path: string }[] = [];
  Object.entries(consentConfigurations).forEach(([scope, definition]) => {
    for (const cookieDefinition of definition.cookies) {
      if (consentScopes.has(scope)) continue;

      for (const cookieName of cookieDefinition.getMatches(cookieNames)) {
        cookiesToDelete.push({
          name: cookieName,
          path: cookieDefinition.path,
        });
      }
    }
  });
  return cookiesToDelete;
};
