export type TrackingConsentSet = Set<string>;

export enum TrackingConsentState {
  consentUnknown = "consent_unknown",
  consentKnown = "consent_known",
}

export type TrackingConsents =
  | {
      state: TrackingConsentState.consentUnknown;
    }
  | {
      state: TrackingConsentState.consentKnown;
      consents: TrackingConsentSet;
    };

export enum CookieLifespan {
  _30minutes = "30minutes",
  _1day = "1day",
  _1year = "1year",
}

export interface CookieDefinition {
  getMatches: (cookies: Set<string>) => string[];
  path: string;
  friendlyName: string;
  actualName: string;
  provider: string;
  purpose: string;
  lifespan: CookieLifespan;
}

export type ConsentScopeDefinition = {
  cookies: CookieDefinition[];
  onConsentUpdated: (newConsents: TrackingConsents) => void;
};

export type ConsentConfigurationMap = { [key: string]: ConsentScopeDefinition };

export interface TrackingConsentContextValueType {
  functionalCookies: CookieDefinition[];
  consentConfigurations: ConsentConfigurationMap;
  isInitialized: Boolean;
  trackingConsent: TrackingConsents;
  onUserUpdatedConsent: (newAcceptedScopes: TrackingConsentSet) => void;
}
