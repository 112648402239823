import React from "react";
import {
  generateStartsWith,
  generateMatchesName,
} from "./helpers/matchingFunctions";
import {
  CookieLifespan,
  TrackingConsents,
  TrackingConsentState,
} from "../components/TrackingConsent/TrackingConsentManagerInterface";

export const GoogleTagManagerConsentScope = "google_tag_manager" as const;
const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

declare global {
  interface Window {
    gtag: (...args: any) => void;
    dataLayer: any[];
  }
}

export const GoogleTagManagerConsentConfiguration = {
  onConsentUpdated: (newConsentState: TrackingConsents) => {
    if (
      newConsentState.state == TrackingConsentState.consentKnown &&
      googleTagManagerId
    ) {
      const shouldLoadTagManager = newConsentState.consents.has(
        GoogleTagManagerConsentScope
      );
      updateGoogleTagManagerState(shouldLoadTagManager, googleTagManagerId);

      if (shouldLoadTagManager) {
        window.gtag("consent", "update", {
          ad_user_data: "granted",
          ad_personalization: "granted",
          ad_storage: "granted",
          analytics_storage: "granted",
        });
      } else {
        window.gtag("consent", "update", {
          ad_user_data: "denied",
          ad_personalization: "denied",
          ad_storage: "denied",
          analytics_storage: "denied",
        });
      }
    }
  },
  cookies: [
    {
      getMatches: generateMatchesName("_gcl_au"),
      path: "/",
      friendlyName: "google_adsense_conversion_tracking",
      actualName: "_gcl_au",
      provider: "google",
      purpose: "google_adsense",
      lifespan: CookieLifespan._1year,
    },
    {
      getMatches: generateMatchesName("_gid"),
      path: "/",
      friendlyName: "google_analytics_gid",
      actualName: "_gid",
      provider: "google",
      purpose: "google_analytics",
      lifespan: CookieLifespan._1year,
    },
    {
      getMatches: generateStartsWith("_ga"),
      path: "/",
      friendlyName: "google_analytics_ga",
      actualName: "_ga",
      provider: "google",
      purpose: "google_analytics",
      lifespan: CookieLifespan._1year,
    },
  ],
};

// This script should always be called as it initializes
// the datalayer. It doesn't load any google scripts.
const dataLayerInitializingScriptContent = `
window.dataLayer = window.dataLayer || [];
function gtag() { 
 dataLayer.push(arguments);
}
window.gtag = gtag;
gtag('consent', 'default', {
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'wait_for_update': 1500,
});
`;

export const GoogleTagManagerDataLayerInitializingScript = () => (
  <script
    dangerouslySetInnerHTML={{ __html: dataLayerInitializingScriptContent }}
  ></script>
);

// This script may only be loaded after the user gave
// consent to be tracked by google.
const googleTagManagerScriptContent = (
  googleTagManagerId: string
) => `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerId}');`;

export const updateGoogleTagManagerState = (
  shouldBeLoaded: Boolean,
  googleTagManagerId: string
) => {
  if (shouldBeLoaded) {
    loadGoogleTagManager(googleTagManagerId);
  } else {
    unLoadGoogleTagManager();
  }
};

const googleTagManagerScriptsAreLoaded = (): Boolean =>
  document.getElementsByClassName("gtag-script").length > 0;

const loadGoogleTagManager = (googleTagManagerId: string) => {
  if (!googleTagManagerScriptsAreLoaded()) {
    injectGoogleTagManagerScripts(googleTagManagerId);
  }
};

const unLoadGoogleTagManager = () => {
  if (googleTagManagerScriptsAreLoaded()) {
    // Reloading the window will remove the script as well as deleting non
    // consented cookies.
    //
    // It is difficult to remove the script on the frontend, because, even if
    // they are removed from the DOM, their code might still be running.
    window.location.reload();
  }
};

const injectGoogleTagManagerScripts = (googleTagManagerId: string) => {
  const element = document.createElement("script");
  element.appendChild(
    document.createTextNode(googleTagManagerScriptContent(googleTagManagerId))
  );
  element.classList.add("gtag-script");
  document.head.append(element);
};

export const sendToGoogleTagManager = (variables: object) => {
  try {
    // eslint-disable-next-line
    const dataLayer = (window as any)?.dataLayer;
    if (dataLayer) {
      dataLayer.push(variables);
    }
  } catch (error) {
    console.warn(error);
  }
};
