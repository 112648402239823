import {
  ConsentConfigurationMap,
  CookieDefinition,
} from "../../components/TrackingConsent/TrackingConsentManagerInterface";
import {
  GoogleTagManagerConsentConfiguration,
  GoogleTagManagerConsentScope,
} from "../googleTagManager";

export const AllConsentScopesSet = new Set(GoogleTagManagerConsentScope);

export const ConsentConfigurations: ConsentConfigurationMap = {
  [GoogleTagManagerConsentScope]: GoogleTagManagerConsentConfiguration,
};

export const FunctionalCookieDefinitions: CookieDefinition[] = [];
